import { handleError, handlePaginatedData } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { appSettings } from '../settings';


//Returns the response data to the component
function handleSuccess( response ) {
  return {
    data: response.data
  } 
}

export const PropertyApi = {
  //Get all the properties from the db
  getProperties: ( action: { token: string, page:number, limit:number, searchCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.Search}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/property/v1/read?${queryParams}`,
        { },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return handlePaginatedData( response, action, total )
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data:  [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.PropertyKey );
      } )
  },

  //Get only one property based on the search value
  getPropertyDetails: ( action: { token: string, searchCode?: string } ) => {
    const queryParams = action.searchCode ? `?${EUrlParams.Search}=` + action.searchCode || '' : '';
    return AxiosClient
      .post( '/property/v1/read' + queryParams,
        { },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err, EAction.Get );
      } )
  },

  //This Api will Delete the property from the db
  deleteProperty: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/property/v1/delete',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response )  
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },

  //update the existing properties in the db
  updateProperty: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/property/v1/update',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Update );
      } )
  },

  //THis Api will create the new property
  createProperty: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/property/v1/create',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Create );
      } )
  },
  //Get all the properties from the db
  getFeaturePropertyAssociations: ( action: { token: string, page:number, limit:number, searchCode?: string ,productId?:string} ) => {
    action.page = action.searchCode ? 1 : action.page
    action.limit = action.searchCode ? 0 : action.limit 
    const pageInfo = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;

    const req = {
      includeFeature: true,
      productCode: action.productId,
      searchCode:action.searchCode ? action.searchCode : null
    }
    return AxiosClient
      .post( `/property/v1/association/read?${pageInfo}`,
        req,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        const total = req.searchCode != null ? 1 : JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.searchCode,
          page: req.searchCode != null ? 1 : action.page,
          totalRecords: total,
          recordsPerPage:action.limit
        }
      } ).catch( ( err ) => {
        return handleError( err,action );
      } )
  },

  //Get all the properties from the db
  getPropertyAssociations: ( action: { token: string, page:number, limit:number, searchCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.ProductSearch}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/property/v1/productmodel/association/read?${queryParams}`,
        { },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return handlePaginatedData( response, action, total )
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data:  [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.PropertyAssociationKey );
      } )
  },

  //Get all the properties from the DB
  getPropertyList: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/property/v1/read',
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return response.data.properties
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },

  //This method returns the List Of Enum Values
  getEnumListOfValues: () => {
    return AxiosClient
      .get( '/lov/v1/read' )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  },
}
