import { Backdrop, Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { useTranslation } from 'react-i18next';
import { ProgresBarwithLabelStyle } from '../../style/Styles';

function CircularProgressWithLabel( props ) {//show circular loading with file upload percentage
  const chunkUpload = useSelector( ( state:AppState ) => state.dataMaintainance.chunkUpload );
  return (
    <Box sx={ ProgresBarwithLabelStyle.root }>
      <CircularProgress size={ 50 } variant="determinate" { ...props } />
      <Box
        sx={ ProgresBarwithLabelStyle.content }
      >
        {chunkUpload.isUploadProgress && `${Math.round( chunkUpload.uploadValue )}%`}
      </Box>
    </Box>
  );
}
/**
 * An overlay with a loading circle which is displayed if there is some background task running
 * @returns {JSX.Element} the overlay with the loading circle component
 */
export const LoadingScreen = ( ) => {
  const {t} = useTranslation();
  const propFileState = useSelector( ( state:AppState ) => state.dataMaintainance.uploadFile.file );
  const chunkUpload = useSelector( ( state:AppState ) => state.dataMaintainance.chunkUpload );
  const busy = useSelector( ( state:AppState ) => state.busy.busy );
  

  return <> 
    {<Backdrop open={ busy } >
      <Box className={ propFileState?.name && ( chunkUpload.isUploadProgress || chunkUpload.isUploaded ) ? 'loading-message' : '' } sx={ {display:'block',textAlign:'center'} }>
        {( Object.keys( propFileState ).length === 0 || chunkUpload.isUploaded || Object.keys( propFileState ).length > 0 && !chunkUpload.isUploadProgress ) &&
         <CircularProgress/>
        }
        {propFileState?.name && chunkUpload.isUploadProgress && <CircularProgressWithLabel value={ chunkUpload.uploadValue }/>}
        {propFileState?.name && chunkUpload.isUploaded &&
   <Box>
     { t( 'messages.success.import' ) }
   </Box>}
      </Box>
    </Backdrop>}
  
  </>
}