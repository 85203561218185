import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppStore, AppDispatch, AppState } from '../../../store';
import { EApiType, EDataTableType, EModuleType } from '../../../data/Constants';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { ErrorMessage } from '../../ErrorMessage';
import { joinSelectedKeys } from '../../../services/DataHelperFunctions';
import { getEnumListOfValues, getProperties, deleteProperty } from '../../../store/states/PropertySlice';
import { IManagementProps } from '../../../../types';
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';

/**
 * Main component to render the Properties Data Table.
 * @param {IManagementProps} props  Management Props
 * @returns {JSX.Element} a  PropertyManagement component.
 */
export const PropertyManagement = ( props: IManagementProps ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )

  //Call get Enum LOV list API 
  useEffect( ()=>{
    dispatch( getEnumListOfValues() );
  },[] )

  //delete the existing properties from the property data table
  const deleteItems = ( req )=>{
    const properties = AppStore.getState().dataMaintainance.properties;
    dispatch( deleteProperty( {token: props.token, request: req} ) ).then( res=>{
      const delKeys = joinSelectedKeys( req )
      if( res.payload?.data ) {
        const message = t( 'messages.success.delete.properties', { length:req.length, list: delKeys } )
        dispatch( setAlertMessage( {show:true, message: message, type:'success'} ) )
        dispatch( getProperties( {token: props.token, page: properties.currentPage, limit: properties.recordsPerPage, searchCode: properties.searchKey} ) );
      }else{
        const message = t( 'messages.fail.delete.properties', { length:req.length, list: delKeys} );
        dispatch( setAlertMessage( {show:true, message:message, type:'error'} ) )
      }
    } )
  }

  //Show the error message if api returns any  error
  if( error?.code && error?.type && error.type === EApiType.PropertyKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <><h3 className="mainHeader"> {t( 'labels.propertyManagement' )}</h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Properties }
      getDataList = { getProperties }
      moduleType = { EModuleType.Datamaintainance }
      deleteItems={ deleteItems }
    /></>
}